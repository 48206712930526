import {
  AspectRatio,
  Box,
  Button,
  Checkbox,
  Dialog,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  SvgIcon,
  Tabs,
  Text,
  Tooltip
} from '@volue/wave-react';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

import {
  GetQuoteButton,
  RequestTrialButton
} from '@/components/Shared/ProductActionButtons';
import { useAppState, useEffects } from '@/overmind';
import type { InsightProductDetails, ProductSummary } from '@/overmind/types';

function ProductDescription(props: {
  id: string;
  label: string;
  variant: 'outline' | 'strong' | 'ghost' | undefined;
}) {
  const { categoryList: categoryListMarketplace } = useAppState().marketplace;
  const { categoryList: categoryListMyProducts } = useAppState().myProducts;
  const [productSummary, setProductSummary] = useState<ProductSummary>();
  const [isAcceptTermsChecked, setIsAcceptTermsChecked] = useState(false);

  const handleClick = (id: string) => {
    const categoryList =
      categoryListMarketplace.length > 0
        ? categoryListMarketplace
        : categoryListMyProducts;
    const productSummary = categoryList
      .flatMap(value => value.products)
      .find(value => value.id === id);
    setProductSummary(productSummary);
  };

  const handleAcceptTermsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsAcceptTermsChecked(event.target.checked);
  };

  const launchButton = () => (
    <Link href={productSummary?.launchButton.url}>
      <Button variant="strong" size={'small'} isNarrow>
        {productSummary?.launchButton.label}
      </Button>
    </Link>
  );

  const requestTrialDialogButton = () => (
    <Dialog>
      <Dialog.Trigger as={Button} variant="strong" size={'small'} isNarrow>
        {'Request trial'}
      </Dialog.Trigger>
      <Dialog.Box>
        <Dialog.Header>
          <Dialog.Title>{'Volue Insight Temporary Access Terms'}</Dialog.Title>
          <Dialog.Close
            as={Button}
            variant="ghost"
            shape="circle"
            size="small"
            withLoneIcon
            aria-label="Close"
            marginLeft="auto"
          >
            <SvgIcon iconName="close" size="medium" />
          </Dialog.Close>
        </Dialog.Header>
        <Dialog.Body withDividers>
          <p>
            {
              'All data, information, news, analyses, calculations, models, forecasts and systems contained in the Services and/or volue.com are provided as-is and is the sole property of Volue or its licensors. All rights in such material are reserved and may not without the prior written consent of Volue be reproduced, published, or summarised for distribution or incorporation into a report or other document, or otherwise relayed or distributed in any form or by any means externally or to third parties or persons in whole or in part.'
            }
          </p>
          <p>
            {
              'If any intellectual property of Volue or its licensors is incorporated into any deliverables provided by Volue as part of Insight, then Volue grants a limited, worldwide, royalty-free, non-exclusive license to use such intellectual property for the duration of the trial period. Volue may discontinue the use in the trial period at any time. '
            }
          </p>
          <p>
            {
              'All access credentials are personal and may not be shared without written consent from Volue. Information received may not be passed on to third parties. All reports from Volue are confidential and neither the form nor content of any reports should be passed on to a third party without the written consent of Volue.'
            }
          </p>
          <p>
            {
              'Volue does not warrant that access to, or use of the Services will be uninterrupted or error- free. The information contained in Volue analyses, newsletters, services and on the Volue website has been obtained from sources believed to be reliable. The information and opinions expressed in the Services, or all deliverables created therefrom, constitute Volue’s best judgements as of the date of release. Any use of Insight during the trial period is at the users own risk and Volue accepts no liability whatsoever for any loss or damage arising from any use of its advisory services, its reports or the contents, prognoses or data in the reports, except in the event of gross negligence or willful misconduct.'
            }
          </p>
          <p>
            {
              'The law of Norway is the governing law, without regard to conflict of law principles.'
            }
          </p>
          <p>
            {
              'Any disputes which may arise shall be finally settled through arbitration according to Norwegian law of arbitration. The arbitration court shall be settled in Oslo and the proceedings shall be conducted in English.'
            }
          </p>
        </Dialog.Body>
        <Dialog.Footer>
          <Flex main="space-between" cross="center" wrap="wrap-reverse">
            <Checkbox
              isChecked={isAcceptTermsChecked}
              onChange={handleAcceptTermsChange}
            >
              <Checkbox.Indicator />
              <Checkbox.Label>{'I agree to these terms.'}</Checkbox.Label>
            </Checkbox>
            <Flex>
              <Dialog.Close
                as={Button}
                variant="outline"
                size={'small'}
                isNarrow
              >
                {'Cancel'}
              </Dialog.Close>
              <RequestTrialButton
                trialStatus={productSummary?.trialStatus ?? 'Available'}
                productContext={{
                  id: productSummary?.id,
                  productOfInterest: productSummary?.name,
                  productLine: productSummary?.productLine,
                  lastAction: undefined
                }}
                acceptTerms={isAcceptTermsChecked}
              />
            </Flex>
          </Flex>
        </Dialog.Footer>
      </Dialog.Box>
    </Dialog>
  );

  let actionButton = <></>;
  if (
    productSummary?.dealType === 'Subscription' ||
    productSummary?.trialStatus === 'Active'
  )
    actionButton = launchButton();
  else if (productSummary?.trialStatus === 'Pending')
    actionButton = (
      <Button variant="strong" size={'small'} isNarrow disabled>
        {'Trial request pending...'}
      </Button>
    );
  else if (productSummary?.trialStatus === 'Expired')
    actionButton = (
      <Tooltip
        content={'Trial period for this product expired. Please contact sales.'}
      >
        <div style={{ flex: '1 1 auto' }}>
          <Button variant="strong" size={'small'} isNarrow disabled>
            {'Trial expired...'}
          </Button>
        </div>
      </Tooltip>
    );
  else if (productSummary?.dealType === 'None')
    actionButton = requestTrialDialogButton();

  const queryParams = queryString.parse(window.location.search);
  const productId = queryParams.productId as string;
  let isOpenAtStart = false;
  if (productId === props.id) {
    if (productSummary === undefined) {
      handleClick(props.id);
    } else {
      isOpenAtStart = true;
    }
  }

  return (
    <Dialog defaultIsOpen={isOpenAtStart}>
      <Dialog.Trigger
        as={Button}
        onClick={() => handleClick(props.id)}
        variant={props.variant}
        size={'small'}
        isNarrow
      >
        {props.label}
      </Dialog.Trigger>
      <Dialog.Box style={{ width: '60em', height: '45em' }}>
        <Dialog.Header>
          <Dialog.Title>
            <Heading as="h2" variant="gamma">
              {productSummary?.name}
            </Heading>
          </Dialog.Title>
          <Dialog.Close
            as={Button}
            variant="ghost"
            shape="circle"
            size="small"
            withLoneIcon
            aria-label="Close"
            margin="none"
            marginLeft="auto"
          >
            <SvgIcon iconName="close" size="medium" />
          </Dialog.Close>
        </Dialog.Header>
        <Dialog.Body withDividers css={{ height: '100%', padding: '2em' }}>
          <DialogContent productSummary={productSummary} />
        </Dialog.Body>
        <Dialog.Footer>
          <Flex>
            {/* <ContactMeButton
              productContext={{
                id: productSummary?.id,
                productOfInterest: productSummary?.name,
                productLine: productSummary?.productLine,
                lastAction: undefined
              }}
            /> */}
            <GetQuoteButton
              productContext={{
                id: productSummary?.id,
                productOfInterest: productSummary?.name,
                productLine: productSummary?.productLine,
                lastAction: undefined
              }}
            />
            <Flex marginLeft="auto">
              <Dialog.Close
                as={Button}
                variant="outline"
                size={'small'}
                isNarrow
              >
                {'Cancel'}
              </Dialog.Close>
              {actionButton}
            </Flex>
          </Flex>
        </Dialog.Footer>
      </Dialog.Box>
    </Dialog>
  );
}

const DialogContent = (props: {
  productSummary: ProductSummary | undefined;
}) => {
  const { getInsightProductDetails } = useEffects().api;

  const [insightProductDetails, setInsightProductDetails] =
    useState<InsightProductDetails>();

  useEffect(() => {
    if (props.productSummary?.id) {
      getInsightProductDetails(props.productSummary?.id).then(value => {
        setInsightProductDetails(value);
      });
    }
  }, [getInsightProductDetails, props.productSummary?.id]);

  return (
    <Grid
      columns="4"
      rows="2"
      gap="spacingXl"
      css={{ height: '100%', gridTemplateRows: 'max-content' }}
    >
      <GridItem
        colStart="1"
        rowStart="1"
        rowSpan="1"
        colSpan="1"
        css={{
          aspectRatio: '1'
        }}
      >
        <ProductPicture url={props.productSummary?.heroImageUrl} />
        <br />
        <br />
        <LanguageSupport sideText={insightProductDetails?.sideText} />
      </GridItem>
      <GridItem colStart="1" rowStart="2" rowSpan="1" colSpan="1" />
      <GridItem colStart="2" rowStart="1" colSpan="3" rowSpan="2">
        <TabContent
          productSummary={props.productSummary}
          insightProductDetails={insightProductDetails}
        />
      </GridItem>
    </Grid>
  );
};

const ProductPicture = (props: { url: string | undefined }) => {
  return (
    <AspectRatio
      ratio={1}
      css={{
        backgroundColor: '$colorGray10',
        alignContent: 'center',
        borderRadius: '12px'
      }}
    >
      <img
        src={props?.url}
        alt=""
        style={{
          borderRadius: '12px',
          overflow: 'hidden',
          objectFit: 'cover'
        }}
      />
    </AspectRatio>
  );
};

const LanguageSupport = (props: { sideText: string | undefined }) => {
  return (
    <Box>
      <Flex flow="column">
        <Text marginBottom="spacingM" css={{ fontSize: '14px' }}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: props.sideText!
            }}
          />
        </Text>
        {/* <Text fontSize="gamma">{'Supported Languages'}</Text>
        <Text color="colorGray50" lineHeight="small">
          {'English'}
        </Text>
        <Text color="colorGray50" lineHeight="small">
          {'German'}
        </Text>
        <Text color="colorGray50" lineHeight="small">
          {'Norwegian'}
        </Text> */}
      </Flex>
    </Box>
  );
};

const TabContent = (props: {
  productSummary: ProductSummary | undefined;
  insightProductDetails: InsightProductDetails | undefined;
}) => {
  const { signedIdUser, integrations } = useAppState();
  return (
    <Tabs defaultValue="description">
      <Tabs.List css={{ marginBottom: '$spacingS' }}>
        <Tabs.Trigger value="description">{'Description'}</Tabs.Trigger>
        {/* <Tabs.Trigger value="availability">{'Availability'}</Tabs.Trigger> */}
        {props.productSummary?.dealType === 'None' ? (
          <></>
        ) : (
          <Tabs.Trigger value="settings">{'Settings'}</Tabs.Trigger>
        )}
        {/* <Tabs.Trigger value="pricing">{'Pricing'}</Tabs.Trigger>
        <Tabs.Trigger value="technical_requirements">
          {'Technical requirements'}
        </Tabs.Trigger>
        <Tabs.Trigger value="modules">{'Modules'}</Tabs.Trigger> */}
      </Tabs.List>
      <Description
        productSummary={props.productSummary}
        insightProductDetails={props.insightProductDetails}
      />
      {/* <Availability insightProductDetails={props.insightProductDetails} /> */}
      {props.productSummary?.dealType === 'None' ? (
        <></>
      ) : (
        <Settings
          identityAdminUrl={integrations.identityAdminUrl}
          tenantId={signedIdUser?.activeTenant?.id ?? ''}
          organizationId={signedIdUser?.activeTenant?.organizationId ?? ''}
        />
      )}
      {/* <Pricing insightProductDetails={insightProductDetails} />
      <TechnicalRequirements insightProductDetails={insightProductDetails} />
      <Modules productSummary={props.productSummary} /> */}
    </Tabs>
  );
};

const Description = (props: {
  productSummary: ProductSummary | undefined;
  insightProductDetails: InsightProductDetails | undefined;
}) => {
  return (
    <Tabs.Content value="description" css={{ height: '93%' }}>
      <Flex
        main="space-between"
        flow="column-reverse"
        css={{
          height: '100%',
          padding: '$spacingS'
        }}
      >
        <Link
          href={props.productSummary?.learnMore?.url}
          isExternal
          css={{ alignSelf: 'end', fontSize: '14px' }}
        >
          {props.productSummary?.learnMore?.label}
        </Link>
        <Text marginBottom="spacingM" variant="small">
          <br />
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: props.insightProductDetails?.longDescription!
            }}
          />
        </Text>
      </Flex>
    </Tabs.Content>
  );
};

// const Availability = (props: {
//   insightProductDetails: InsightProductDetails | undefined;
// }) => {
//   return (
//     <Tabs.Content value="availability" css={{ padding: '$spacingS' }}>
//       <Text marginBottom="spacingM" marginTop="spacingM" variant="small">
//         <br />
//         <div
//           // eslint-disable-next-line react/no-danger
//           dangerouslySetInnerHTML={{
//             __html: props.insightProductDetails?.availability!
//           }}
//         />
//       </Text>
//     </Tabs.Content>
//   );
// };

const Settings = (props: {
  identityAdminUrl: string;
  tenantId: string;
  organizationId: string;
}) => {
  const url = `${props.identityAdminUrl}organization/${props.organizationId}/tenant/${props.tenantId}/members`;
  return (
    <Tabs.Content value="settings" css={{ padding: '$spacingS' }}>
      <a href={url}>{'Manage members'}</a>
    </Tabs.Content>
  );
};

/* const Pricing = (props: {
  insightProductDetails: InsightProductDetails | undefined;
}) => {
  return (
    <Tabs.Content value="pricing" css={{ padding: '$spacingS' }}>
      <Box as="p">{props.insightProductDetails?.availability}</Box>
    </Tabs.Content>
  );
};

const TechnicalRequirements = (props: {
  insightProductDetails: InsightProductDetails | undefined;
}) => {
  return (
    <Tabs.Content value="technical_requirements" css={{ padding: '$spacingS' }}>
      <Box as="p">{props.insightProductDetails?.technicalRequirements}</Box>
    </Tabs.Content>
  );
};

const Modules = (props: { productSummary: ProductSummary | undefined }) => {
  const { getProductsById } = useEffects().api;

  const [products, setProducts] = useState<ProductCategory[]>([]);

  useEffect(() => {
    if (props.productSummary?.id) {
      getProductsById(props.productSummary?.id).then(value => {
        setProducts(value);
      });
    }
  }, [getProductsById, props.productSummary?.id]);

  return (
    <Tabs.Content value="modules" css={{ padding: '$spacingS' }}>
      <Flex flow="column">
        {products.map(p =>
          p.products.map(value => {
            return (
              <Box
                key={value.code}
                css={{
                  boxShadow: '$elevationS',
                  borderRadius: '$m',
                  padding: '$spacingM'
                }}
              >
                <Flex flow="row" main="space-between">
                  {value.module}
                  <Switch>
                    <Switch.Indicator />
                  </Switch>
                </Flex>
              </Box>
            );
          })
        )}
      </Flex>
    </Tabs.Content>
  );
}; */

export default ProductDescription;
